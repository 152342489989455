import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2865 1189 c31 -8 43 -14 31 -17 -15 -3 -16 -6 -5 -13 10 -6 10 -9 2
-9 -9 0 -13 -16 -13 -45 0 -28 -4 -45 -12 -45 -9 0 -9 -3 0 -12 18 -18 14 -31
-8 -25 -26 7 -26 -1 -1 -11 11 -4 -85 -7 -212 -7 -257 0 -362 -14 -549 -76
-136 -44 -398 -176 -398 -200 0 -3 46 -9 103 -12 263 -15 429 -135 452 -328 9
-76 -8 -132 -65 -210 l-48 -67 54 -53 c30 -30 67 -79 82 -109 24 -48 27 -67
27 -150 -1 -77 -5 -105 -23 -145 -45 -99 -137 -165 -291 -207 -68 -19 -109
-22 -398 -26 l-323 -4 0 447 c0 298 -3 444 -10 440 -15 -9 -78 -110 -121 -194
-85 -165 -147 -351 -179 -535 l-18 -101 -1 132 c-2 300 85 583 267 865 l62 95
0 76 0 77 53 2 c28 0 57 2 62 3 6 2 15 3 22 4 6 0 34 21 61 46 251 229 570
379 900 424 90 13 434 5 497 -10z m-205 -469 l88 0 54 -67 c29 -38 61 -77 70
-88 29 -35 229 -289 248 -315 10 -14 20 -23 23 -20 5 6 194 267 282 390 37 52
73 98 79 103 12 8 177 8 208 -1 17 -4 18 -35 18 -653 l0 -649 -172 2 -173 3
-2 302 c-1 167 -4 304 -7 305 -2 2 -37 -40 -76 -92 -100 -132 -96 -128 -170
-136 l-65 -6 -99 121 c-55 66 -103 118 -108 115 -4 -3 -8 -142 -8 -310 l0
-304 -170 0 -170 0 0 649 c0 610 1 650 18 654 9 3 23 3 30 1 8 -2 53 -4 102
-4z m-780 -1465 l0 -115 -30 0 -30 0 0 115 0 115 30 0 30 0 0 -115z m1590 80
c0 -33 2 -35 32 -35 46 0 68 -25 68 -77 0 -64 -24 -83 -100 -81 l-60 2 0 113
0 113 30 0 c28 0 30 -3 30 -35z m-1881 9 c20 -14 22 -23 18 -55 -3 -22 -1 -42
4 -45 14 -8 11 -58 -5 -79 -11 -16 -30 -20 -90 -23 l-76 -4 0 111 0 111 63 0
c42 0 70 -5 86 -16z m1063 -26 c2 -15 11 -24 26 -26 14 -2 22 -10 22 -22 0
-12 -8 -20 -22 -22 -32 -4 -40 -58 -9 -58 21 0 46 -32 34 -44 -3 -3 -21 -6
-39 -6 -39 0 -74 38 -74 81 0 16 -4 29 -10 29 -5 0 -10 9 -10 20 0 11 5 20 10
20 6 0 10 8 10 18 0 24 9 32 36 32 16 0 24 -7 26 -22z m-886 -35 c8 -10 14
-44 14 -78 l0 -61 -59 -1 c-53 -2 -59 0 -72 23 -17 32 -4 55 41 70 20 7 28 14
21 18 -6 4 -20 5 -30 1 -19 -6 -36 18 -25 36 10 17 96 10 110 -8z m270 0 c19
-26 19 -143 0 -143 -8 0 -18 5 -22 11 -4 8 -9 8 -17 0 -18 -18 -65 -13 -77 8
-24 45 -2 81 50 81 13 0 18 4 14 11 -3 6 -17 8 -29 5 -26 -7 -40 7 -31 29 8
22 95 20 112 -2z m306 4 c4 -7 8 -42 8 -79 l0 -68 -30 0 c-30 0 -31 0 -29 55
2 61 -1 68 -25 59 -12 -5 -16 -19 -16 -60 0 -53 0 -54 -30 -54 -30 0 -30 1
-30 54 0 57 -7 70 -34 60 -12 -5 -16 -20 -16 -60 0 -53 0 -54 -30 -54 l-30 0
0 78 0 79 127 0 c87 1 130 -3 135 -10z m201 -25 c36 -61 -4 -122 -79 -122 -55
0 -84 28 -84 80 0 57 30 82 93 78 46 -3 53 -6 70 -36z m337 16 c30 -33 27 -91
-6 -117 -32 -25 -90 -28 -121 -6 -30 21 -38 66 -19 103 18 36 28 40 83 41 31
1 48 -5 63 -21z m85 12 c4 -7 12 -7 25 0 29 16 40 12 40 -14 0 -15 -7 -26 -20
-29 -17 -4 -20 -14 -20 -56 0 -50 -1 -51 -30 -51 l-30 0 0 80 c0 63 3 80 14
80 8 0 18 -5 21 -10z m208 4 c15 -15 -13 -41 -38 -37 -35 7 -31 -1 10 -26 24
-14 35 -28 35 -44 0 -33 -19 -47 -65 -47 -49 0 -68 11 -59 34 5 13 13 16 35
11 33 -7 40 10 9 20 -77 24 -59 95 24 95 23 0 46 -3 49 -6z m89 -51 c3 -50 6
-58 23 -58 17 0 20 8 23 58 3 56 4 57 32 57 l30 0 0 -77 0 -78 -73 -2 c-70 -2
-75 -1 -85 23 -7 14 -12 49 -12 79 0 54 0 55 30 55 28 0 29 -1 32 -57z"/>
<path d="M2858 3673 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2843 3653 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M1611 2841 l1 -111 64 0 c94 0 163 18 196 50 32 32 35 58 13 100 -24
47 -67 63 -178 68 l-98 4 2 -111z"/>
<path d="M1615 2448 c-3 -8 -4 -68 -3 -134 l3 -119 112 1 c132 1 181 17 209
69 26 48 15 112 -26 147 -39 32 -57 36 -187 44 -85 6 -103 4 -108 -8z"/>
<path d="M3470 1720 c0 -34 3 -41 18 -38 12 2 17 13 17 38 0 25 -5 36 -17 38
-15 3 -18 -4 -18 -38z"/>
<path d="M1507 1814 c-16 -16 -6 -34 19 -34 19 0 25 5 22 17 -3 19 -29 29 -41
17z"/>
<path d="M1500 1711 c0 -17 6 -21 31 -21 24 0 30 4 27 18 -2 10 -14 18 -31 20
-22 3 -27 -1 -27 -17z"/>
<path d="M1697 1703 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M1965 1699 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M2453 1753 c-7 -2 -13 -18 -13 -34 0 -24 4 -29 25 -29 14 0 26 5 26
10 1 6 2 16 3 23 2 13 -13 37 -23 36 -3 0 -12 -3 -18 -6z"/>
<path d="M2790 1745 c-19 -23 -8 -50 23 -53 24 -3 27 0 27 26 0 18 -6 32 -16
36 -21 8 -20 8 -34 -9z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
